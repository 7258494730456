import React from 'react'
import { ComponentWhatYouGetModule } from 'types/generated/contentful-types'
import { WhatYouGetModuleParallax } from './WhatYouGetModuleParallax'
import { WhatYouGetModuleTabs } from './WhatYouGetModuleTabs'

export enum WhatYouGetModuleDisplay {
  Parallax = 'Parallax',
  Tabs = 'Tabs',
}

type ComponentWhatYouGetModuleWithoutId = Omit<ComponentWhatYouGetModule, '_id'>

export type WhatYouGetModuleTemplateProps = Pick<
  ComponentWhatYouGetModuleWithoutId,
  'whatYouGetCardsCollection'
>

export default function WhatYouGetModule(
  props: ComponentWhatYouGetModuleWithoutId
): JSX.Element {
  const { sectionId, display, headline, subHead } = props
  return (
    <section id={sectionId ?? undefined}>
      {headline && (
        <div
          className="max-w-[80%] md:max-w-[584px] m-auto text-center mb-18"
          data-name="heading"
        >
          <h2 className="mb-6 text-sans-h4 md:fora-text-h2 md:text-sans-h2">
            {headline}
          </h2>
          {subHead && (
            <h3 className="md:text-[48px] md:fora-text-h4 itinerary-title-card">
              {subHead}
            </h3>
          )}
        </div>
      )}

      {display === WhatYouGetModuleDisplay.Tabs ? (
        <WhatYouGetModuleTabs {...props} />
      ) : (
        <WhatYouGetModuleParallax {...props} />
      )}
    </section>
  )
}
