/**
 * Customizable and accessible tabs that are independent of style. A tab reveals a tab panel when selected, and that's it.
 * To select tab, click on tab, or use arrow keys to navigate between tabs.
 *
 * Components:
 * 1. Tabs - Context provider that manages state of active tab.
 * 2. Tab - A tab button
 * 3. TabPanel - Content for the tab
 *
 * e.g.
 *
 * <Tabs defaultTabIndex={0}>
 *  <section>
 *   <ul className="flex">
 *     <li>
 *       <Tab tabIndex={0}>Tab 1</Tab>
 *     </li>
 *     <li>
 *       <Tab tabIndex={1}>Tab 2</Tab>
 *     </li>
 *     <li>
 *       <Tab tabIndex={2}>Tab 3</Tab>
 *     </li>
 *   </ul>
 *   <TabPanel tabIndex={0} activeClassName="block" inactiveClassName="hidden">
 *    Content of Tab 1
 *   </TabPanel>
 *   <TabPanel tabIndex={1} activeClassName="block" inactiveClassName="hidden">
 *    Content of Tab 2
 *   </TabPanel>
 *   <TabPanel tabIndex={2} activeClassName="block" inactiveClassName="hidden">
 *    Content of Tab 3
 *   </TabPanel>
 *  </section>
 * </Tabs>
 */

import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from 'react'

type TrackingTabs = {
  activeTab: number
  previousTab?: number
}

type TabsContextProps = TrackingTabs & {
  selectTab: (tabIndex: number) => void
  highestIndex: number
  setHighestIndex: React.Dispatch<React.SetStateAction<number>>
  keyPressed: boolean
  setKeyPressed: React.Dispatch<React.SetStateAction<boolean>>
}

export const TabsContext = createContext<TabsContextProps>({
  activeTab: 0,
  previousTab: undefined,
  selectTab: () => {
    return
  },
  highestIndex: 0,
  setHighestIndex: () => {
    return
  },
  keyPressed: false,
  setKeyPressed: () => {
    return
  },
})

export const Tabs = ({
  defaultTabIndex = 0,
  children,
}: {
  defaultTabIndex?: number
  children: React.ReactNode
}) => {
  const [{ activeTab, previousTab }, setTrackingTabs] = useState<TrackingTabs>({
    activeTab: defaultTabIndex,
  })
  const [highestIndex, setHighestIndex] = useState<number>(0)
  const [keyPressed, setKeyPressed] = useState<boolean>(false)

  const selectTab = (tabIndex: number) =>
    setTrackingTabs({ activeTab: tabIndex, previousTab: activeTab })

  return (
    <TabsContext.Provider
      value={{
        activeTab,
        previousTab,
        selectTab,
        highestIndex,
        setHighestIndex,
        keyPressed,
        setKeyPressed,
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}

/**
 * Tab component
 * @param tabIndex - Index of tab
 * @param className - Optionally provide classes
 * @param activeClassName - Optionally provide classes that are added when tab is active
 * @param inactiveClassName - Optionally provide classes that are added when tab is inactive
 * @param onSelectAnimation - Optionally provide function that animates when tab is selected (make sure not to clash if using activeClassName)
 * @param onDeselectAnimation - Optionally provide function that animates when tab is deselected (make sure not to clash if using inactiveClassName)
 */

interface TabProps {
  tabIndex: number
  children: React.ReactNode
  className?: string
  activeClassName?: string
  inactiveClassName?: string
  onSelect?: () => void
  onSelectAnimation?: TabsAnimationFunction
  onDeselectAnimation?: TabsAnimationFunction
  onMouseEnterAnimation?: TabsAnimationFunction
  onMouseLeaveAnimation?: TabsAnimationFunction
}

export const Tab = ({
  tabIndex,
  children,
  className,
  activeClassName,
  inactiveClassName,
  onSelect,
  onSelectAnimation,
  onDeselectAnimation,
  onMouseEnterAnimation,
  onMouseLeaveAnimation,
}: TabProps) => {
  const {
    activeTab,
    previousTab,
    selectTab,
    highestIndex,
    setHighestIndex,
    keyPressed,
    setKeyPressed,
  } = useContext(TabsContext)
  const ref = useRef<HTMLButtonElement>(null)
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false)
  const wasActive = useRef(false)
  const isActive = activeTab === tabIndex
  const totalTabs = highestIndex + 1

  const onClick = () => (selectTab(tabIndex), onSelect && onSelect())

  // Keyboard navigation
  const onKeyDown = (event) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      event.preventDefault()
      const nextIndex =
        event.key === 'ArrowRight'
          ? (tabIndex + 1) % totalTabs
          : (tabIndex - 1 + totalTabs) % totalTabs
      selectTab(nextIndex)
      onSelect && onSelect()
      if (!keyPressed) setKeyPressed(true)
    }
  }

  // Hover
  const onMouseEnter = () => {
    if (ref.current && onMouseEnterAnimation)
      onMouseEnterAnimation(ref.current, activeTab, previousTab)
  }
  const onMouseLeave = () => {
    if (ref.current && onMouseLeaveAnimation)
      onMouseLeaveAnimation(ref.current, activeTab, previousTab)
  }

  // Record highest tab index to get total number of tabs
  useEffect(() => {
    setHighestIndex((prev) => Math.max(prev, tabIndex))
  }, [tabIndex, setHighestIndex])

  // Focus on tab when selected by key press
  useEffect(() => {
    if (isActive && ref.current && keyPressed) ref.current.focus()
  }, [isActive, keyPressed])

  // Run animation functions on select
  useEffect(() => {
    if (!isPageLoaded) return setIsPageLoaded(true)
    if (isActive && !wasActive.current && onSelectAnimation) {
      if (ref.current) onSelectAnimation(ref.current, activeTab, previousTab)
    } else if (!isActive && wasActive.current && onDeselectAnimation) {
      if (ref.current) onDeselectAnimation(ref.current, activeTab, previousTab)
    }
    wasActive.current = isActive
  }, [isPageLoaded, isActive, onSelectAnimation, onDeselectAnimation]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <button
      ref={ref}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      aria-selected={isActive}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-label="Use the left and right arrow keys to navigate between tabs"
      className={`${className} ${
        isActive ? activeClassName : inactiveClassName
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-index={tabIndex.toString()}
    >
      {children}
    </button>
  )
}

/**
 * Tab Panel component
 * @param tabIndex - Index of tab
 * @param className - Optionally provide classes
 * @param activeClassName - Optionally provide classes that are added when tab is active
 * @param inactiveClassName - Optionally provide classes that are added when tab is inactive
 * @param onSelectAnimation - Optionally provide function that animates when tab is selected (make sure not to clash if using activeClassName)
 * @param onDeselectAnimation - Optionally provide function that animates when tab is deselected (make sure not to clash if using inactiveClassName)
 */

interface TabPanelProps {
  tabIndex: number
  children: React.ReactNode
  className?: string
  activeClassName?: string
  inactiveClassName?: string
  onSelectAnimation?: TabsAnimationFunction
  onDeselectAnimation?: TabsAnimationFunction
  style: React.CSSProperties
}

export const TabPanel = ({
  tabIndex,
  children,
  className,
  activeClassName,
  inactiveClassName,
  onSelectAnimation,
  onDeselectAnimation,
  style,
}: TabPanelProps) => {
  const { activeTab, previousTab } = useContext(TabsContext)
  const ref = useRef<HTMLDivElement>(null)
  const isPageLoaded = useRef(false)
  const wasActive = useRef(false)
  const isActive = activeTab === tabIndex

  // Run animation functions
  useEffect(() => {
    if (!isPageLoaded.current) {
      isPageLoaded.current = true
      return
    }
    if (isActive && !wasActive.current && onSelectAnimation) {
      if (ref.current) onSelectAnimation(ref.current, activeTab, previousTab)
    } else if (!isActive && wasActive.current && onDeselectAnimation) {
      if (ref.current) onDeselectAnimation(ref.current, activeTab, previousTab)
    }
    wasActive.current = isActive
  }, [isPageLoaded, isActive, onSelectAnimation, onDeselectAnimation]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={ref}
      role="tabpanel"
      aria-hidden={!isActive}
      className={`${className} 
      ${isActive ? activeClassName : inactiveClassName}`}
      style={style}
    >
      {children}
    </div>
  )
}

export type TabsAnimationFunction = (
  element: HTMLElement,
  activeTab: number,
  previousTab?: number
) => void
